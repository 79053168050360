.pf-c-form-control {
  --pf-global--Color--100: var(--pf-global--Color--dark-100);
  --pf-global--Color--200: var(--pf-global--Color--dark-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
  --pf-global--link--Color: var(--pf-global--link--Color--dark);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100); }

.pf-c-form-control {
  --pf-c-form-control--FontSize: var(--pf-global--FontSize--md);
  --pf-c-form-control--LineHeight: var(--pf-global--LineHeight--md);
  --pf-c-form-control--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-form-control--BorderTopColor: var(--pf-global--BorderColor--300);
  --pf-c-form-control--BorderRightColor: var(--pf-global--BorderColor--300);
  --pf-c-form-control--BorderBottomColor: var(--pf-global--BorderColor--200);
  --pf-c-form-control--BorderLeftColor: var(--pf-global--BorderColor--300);
  --pf-c-form-control--BorderRadius: 0;
  --pf-c-form-control--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-form-control--Height: calc(var(--pf-c-form-control--FontSize) * var(--pf-c-form-control--LineHeight) + var(--pf-c-form-control--BorderWidth) * 2 + var(--pf-c-form-control--PaddingTop) + var(--pf-c-form-control--PaddingBottom));
  --pf-c-form-control--PaddingTop: calc(var(--pf-global--spacer--form-element) - var(--pf-global--BorderWidth--sm));
  --pf-c-form-control--PaddingBottom: calc(var(--pf-global--spacer--form-element) - var(--pf-global--BorderWidth--sm));
  --pf-c-form-control--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-form-control--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-form-control--hover--BorderBottomColor: var(--pf-global--primary-color--100);
  --pf-c-form-control--focus--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-form-control--focus--PaddingBottom: calc(var(--pf-global--spacer--form-element) - var(--pf-c-form-control--focus--BorderBottomWidth));
  --pf-c-form-control--focus--BorderBottomColor: var(--pf-global--primary-color--100);
  --pf-c-form-control--placeholder--Color: var(--pf-global--Color--dark-200);
  --pf-c-form-control--disabled--Color: var(--pf-global--disabled-color--100);
  --pf-c-form-control--disabled--BackgroundColor: var(--pf-global--disabled-color--300);
  --pf-c-form-control--disabled--BorderColor: transparent;
  --pf-c-form-control--readonly--focus--BackgroundColor: var(--pf-global--disabled-color--300);
  --pf-c-form-control--readonly--focus--PaddingBottom: var(--pf-c-form-control--PaddingBottom);
  --pf-c-form-control--readonly--focus--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-form-control--readonly--focus--BorderBottomColor: var(--pf-global--disabled-color--100);
  --pf-c-form-control--invalid--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-form-control--invalid--PaddingBottom: calc(var(--pf-global--spacer--form-element) - var(--pf-c-form-control--invalid--BorderBottomWidth));
  --pf-c-form-control--invalid--BorderBottomColor: var(--pf-global--danger-color--100);
  --pf-c-form-control--invalid--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-form-control--invalid--BackgroundPosition: calc(100% - var(--pf-c-form-control--PaddingLeft)) var(--pf-c-form-control--PaddingLeft);
  --pf-c-form-control--invalid--BackgroundSize: var(--pf-c-form-control--FontSize) var(--pf-c-form-control--FontSize);
  --pf-c-form-control--invalid--BackgroundUrl: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23c9190b' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
  --pf-c-form-control--invalid--exclamation--Background: var(--pf-c-form-control--invalid--BackgroundUrl) var(--pf-c-form-control--invalid--BackgroundPosition) / var(--pf-c-form-control--invalid--BackgroundSize) no-repeat;
  --pf-c-form-control--invalid--Background: var(--pf-c-form-control--BackgroundColor) var(--pf-c-form-control--invalid--exclamation--Background);
  --pf-c-form-control--success--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-form-control--success--PaddingBottom: calc(var(--pf-global--spacer--form-element) - var(--pf-c-form-control--success--BorderBottomWidth));
  --pf-c-form-control--success--BorderBottomColor: var(--pf-global--success-color--100);
  --pf-c-form-control--success--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-form-control--success--BackgroundPosition: calc(100% - var(--pf-c-form-control--PaddingLeft)) var(--pf-c-form-control--PaddingLeft);
  --pf-c-form-control--success--BackgroundSize: var(--pf-c-form-control--FontSize) var(--pf-c-form-control--FontSize);
  --pf-c-form-control--success--BackgroundUrl: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%2392d400' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'/%3E%3C/svg%3E");
  --pf-c-form-control--success--check--Background: var(--pf-c-form-control--success--BackgroundUrl) var(--pf-c-form-control--success--BackgroundPosition) / var(--pf-c-form-control--success--BackgroundSize) no-repeat;
  --pf-c-form-control--success--Background: var(--pf-c-form-control--BackgroundColor) var(--pf-c-form-control--success--check--Background);
  --pf-c-form-control--m-search--PaddingLeft: var(--pf-global--spacer--xl);
  --pf-c-form-control--m-search--BackgroundPosition: var(--pf-c-form-control--PaddingLeft);
  --pf-c-form-control--m-search--BackgroundSize: var(--pf-c-form-control--FontSize) var(--pf-c-form-control--FontSize);
  --pf-c-form-control--m-search--BackgroundUrl: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23737679' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/%3E%3C/svg%3E");
  --pf-c-form-control--m-search--Background: var(--pf-c-form-control--m-search--BackgroundUrl) var(--pf-c-form-control--m-search--BackgroundPosition) / var(--pf-c-form-control--m-search--BackgroundSize) no-repeat;
  --pf-c-form-control__select--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-form-control__select--BackgroundUrl: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23urrentColor' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
  --pf-c-form-control__select--BackgroundSize: 0.875rem;
  --pf-c-form-control__select--BackgroundPosition: calc(100% - var(--pf-global--spacer--sm)) center;
  --pf-c-form-control__select--arrow--Background: var(--pf-c-form-control--BackgroundColor) var(--pf-c-form-control__select--BackgroundUrl) var(--pf-c-form-control__select--BackgroundPosition) / var(--pf-c-form-control__select--BackgroundSize) no-repeat;
  --pf-c-form-control__select--Background: var(--pf-c-form-control__select--arrow--Background);
  --pf-c-form-control__select--invalid--Background: var(--pf-c-form-control--invalid--exclamation--Background), var(--pf-c-form-control__select--arrow--Background);
  --pf-c-form-control__select--invalid--PaddingRight: calc(var(--pf-global--spacer--sm) + var(--pf-global--spacer--2xl));
  --pf-c-form-control__select--success--Background: var(--pf-c-form-control--success--check--Background), var(--pf-c-form-control__select--arrow--Background);
  --pf-c-form-control__select--success--PaddingRight: calc(var(--pf-global--spacer--sm) + var(--pf-global--spacer--2xl));
  color: var(--pf-global--Color--100);
  width: 100%;
  padding: var(--pf-c-form-control--PaddingTop) var(--pf-c-form-control--PaddingRight) var(--pf-c-form-control--PaddingBottom) var(--pf-c-form-control--PaddingLeft);
  font-size: var(--pf-c-form-control--FontSize);
  line-height: var(--pf-c-form-control--LineHeight);
  background-color: var(--pf-c-form-control--BackgroundColor);
  border: var(--pf-c-form-control--BorderWidth) solid;
  border-color: var(--pf-c-form-control--BorderTopColor) var(--pf-c-form-control--BorderRightColor) var(--pf-c-form-control--BorderBottomColor) var(--pf-c-form-control--BorderLeftColor);
  border-radius: var(--pf-c-form-control--BorderRadius);
  -moz-appearance: none;
  -webkit-appearance: none; }
  .pf-c-form-control::placeholder {
    --pf-c-form-control--Color: var(--pf-c-form-control--placeholder--Color); }
  .pf-c-form-control:not(textarea) {
    height: var(--pf-c-form-control--Height);
    text-overflow: ellipsis; }
  .pf-c-form-control[readonly], .pf-c-form-control[readonly].pf-m-hover, .pf-c-form-control[readonly]:hover, .pf-c-form-control[readonly].pf-m-focus, .pf-c-form-control[readonly]:focus {
    --pf-c-form-control--BorderBottomColor: var(--pf-c-form-control--readonly--focus--BorderBottomColor);
    padding-bottom: var(--pf-c-form-control--readonly--focus--PaddingBottom);
    background-color: var(--pf-c-form-control--readonly--focus--BackgroundColor);
    border-bottom-width: var(--pf-c-form-control--readonly--focus--BorderBottomWidth); }
  .pf-c-form-control.pf-m-hover, .pf-c-form-control:hover {
    --pf-c-form-control--BorderBottomColor: var(--pf-c-form-control--hover--BorderBottomColor); }
  .pf-c-form-control.pf-m-focus, .pf-c-form-control:focus {
    --pf-c-form-control--BorderBottomColor: var(--pf-c-form-control--focus--BorderBottomColor);
    padding-bottom: var(--pf-c-form-control--focus--PaddingBottom);
    border-bottom-width: var(--pf-c-form-control--focus--BorderBottomWidth); }
  .pf-c-form-control:disabled {
    --pf-c-form-control--Color: var(--pf-c-form-control--disabled--Color);
    --pf-c-form-control--BackgroundColor: var(--pf-c-form-control--disabled--BackgroundColor);
    cursor: not-allowed;
    border-color: var(--pf-c-form-control--disabled--BorderColor); }
  .pf-c-form-control[aria-invalid="true"] {
    --pf-c-form-control--PaddingRight: var(--pf-c-form-control--invalid--PaddingRight);
    --pf-c-form-control--BorderBottomColor: var(--pf-c-form-control--invalid--BorderBottomColor);
    padding-bottom: var(--pf-c-form-control--invalid--PaddingBottom);
    background: var(--pf-c-form-control--invalid--Background);
    border-bottom-width: var(--pf-c-form-control--invalid--BorderBottomWidth); }
  .pf-c-form-control.pf-m-success {
    --pf-c-form-control--PaddingRight: var(--pf-c-form-control--success--PaddingRight);
    --pf-c-form-control--BorderBottomColor: var(--pf-c-form-control--success--BorderBottomColor);
    padding-bottom: var(--pf-c-form-control--success--PaddingBottom);
    background: var(--pf-c-form-control--success--Background);
    border-bottom-width: var(--pf-c-form-control--success--BorderBottomWidth); }
  .pf-c-form-control.pf-m-search {
    padding-left: var(--pf-c-form-control--m-search--PaddingLeft);
    background: var(--pf-c-form-control--m-search--Background); }
  select.pf-c-form-control {
    --pf-c-form-control--PaddingRight: var(--pf-c-form-control__select--PaddingRight);
    background: var(--pf-c-form-control__select--Background); }
    select.pf-c-form-control[aria-invalid="true"] {
      --pf-c-form-control--PaddingRight: var(--pf-c-form-control__select--invalid--PaddingRight);
      --pf-c-form-control--invalid--BackgroundPosition: calc(100% - var(--pf-global--spacer--sm) - var(--pf-global--spacer--lg));
      --pf-c-form-control--invalid--Background: var(--pf-c-form-control__select--invalid--Background); }
    select.pf-c-form-control.pf-m-success {
      --pf-c-form-control--PaddingRight: var(--pf-c-form-control__select--success--PaddingRight);
      --pf-c-form-control--success--BackgroundPosition: calc(100% - var(--pf-global--spacer--sm) - var(--pf-global--spacer--lg));
      --pf-c-form-control--success--Background: var(--pf-c-form-control__select--success--Background); }
  .pf-c-form-control.pf-m-resize-vertical {
    resize: vertical; }
  .pf-c-form-control.pf-m-resize-horizontal {
    resize: horizontal; }
